import { FC, ReactNode } from "react";
import joinClassNames from "classnames";

import ErrorIcon from "icons/error.svg?react";

import classes from "./styles/classes.module.scss";

interface Props {
  children?: ReactNode;
  title?: string;
  subtitle?: string;
  isBackground?: boolean;
  className?: string;
  classNames?: Partial<{
    icon: string;
  }>;
}

const ErrorScreen: FC<Props> = ({
  children,
  title = "Error",
  subtitle = "It seems like an unknown error has occurred",
  isBackground = true,
  className,
  classNames = {},
}) => (
  <div className={joinClassNames(classes.centerContainer, className)}>
    <ErrorIcon
      data-background={isBackground}
      className={joinClassNames(classes.errorIcon, classNames.icon)}
    />
    <div>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.description}>{subtitle}</div>
    </div>
    {children}
  </div>
);

export default ErrorScreen;
